import { Button, Image } from "react-bootstrap";

import leftArrow from "../../assets/images/arrow-left-icon.svg";
import { useNavigate } from "react-router-dom";
import { TERMS } from "../../AppConstants/AppConstants";

export const Term = () => {
  const navigate = useNavigate();

  //handle to go to previous page
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleTermsPage = () => {
    console.log('term')
    navigate(TERMS);
  }

  return (
    <div className="sg-main-wrap d-flex flex-column flex-1 align-items-center scroll-y">
      <div className="sg-content-section d-flex flex-column flex-1 position-relative scroll-y py-5">
        {/* <HeaderComponent /> */}
        <div className="terms-content-wrap d-flex flex-column flex-1 scroll-y px-3 pb-5">
          <h2 className=" fw-bold mb-3">
          Terms
          </h2>
          <p>Yes, I agree The Prudential Insurance Company Of America,Pruco Securities,LLC and their affiliates ("Prudential") may contact me for marketing purposes by phone call, e-mail or text (including with an auto dialer and even if I've requested not to be contacted by Prudential or am on a do-not-call/contact list) at the phone number or email address listed above (message and data rates may apply). I also understand that consent isn't necessary to make an investment or purchase.</p>
          <p>To continue without providing this consent please call 844-PRU-LINK (844-778-5465) Monday - Friday, 9 a.m -6 p.m .ET (except New York Stock ,Exchange holidays).</p>
          
        </div>
        <div className="back-fix-btn w-100 p-3 d-flex align-items-center justify-content-center">
          <Button
            variant="primary"
            className="fs-md text-uppercase fw-bold rounded-0 py-1 px-3 text-dark text-decoration-none"
            onClick={handleGoBack}
          >
            <Image src={leftArrow} alt="right arrow" color="#000000" className="me-2 text-black" />
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};
