import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "./../../assets/images/header.png";

export default function Confirmation() {
  const navigate = useNavigate();
  return (
    <div
      style={{ height: "100%", backgroundColor: "#5C5C5C", maxWidth: "420px", margin: "auto" }}
      className="d-flex flex-column justify-content-between  text-center"
    >
      <img style={{ width: "100%" }} src={Header} alt="" />
      <div className="mx-4">
        <h4 style={{ fontWeight: "bold", color: "yellow" }}>THANK YOU!</h4>
        <p>
          We appreciate you taking part in this experience. You will receive a text message shortly providing
          instructions to receive your customized hat.
        </p>
        <p> If you did not receive a text message, please try again by tapping below.</p>
        <Button variant="outline-light" className="px-5 btn-lg border-2 w-100 mt-5" onClick={() => navigate("/", { replace: true })}>
          START OVER
        </Button>
      </div>

      <p style={{ fontSize: "12px" }}>@ 2023 Prudentails Financial, Inc. and its related entities. 1074232-00001-00</p>
    </div>
  );
}
