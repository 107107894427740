import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { APP_BASE_ROUTE, ROUTES } from "./AppConstants/AppConstants";

export const RoutesConfig = () => {
  return (
    <BrowserRouter basename={APP_BASE_ROUTE}>
      <Toaster toastOptions={{ className: "cu-toaster border border-secondary" }} containerStyle={{ top: "50%" }} />
      <Routes>
        {ROUTES.map((route, i) => (
          <Route key={i} exact path={route.path} element={route.element}></Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
};
