import { Button, Image } from "react-bootstrap";

import leftArrow from "../../assets/images/arrow-left-icon.svg";
import { useNavigate } from "react-router-dom";

export const ReleaseComponent = () => {
  const navigate = useNavigate();

  //handle to go to previous page
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="sg-main-wrap d-flex flex-column flex-1 align-items-center scroll-y">
      <div className="sg-content-section d-flex flex-column flex-1 position-relative scroll-y py-5">
        {/* <HeaderComponent /> */}
        <div className="terms-content-wrap d-flex flex-column flex-1 scroll-y px-3 pb-5">
          {/* <p className="MsoNormal" style={{ textAlign: 'center' }}>
            <span style={{ color: 'white' }}>\</span>
          </p> */}
          
          <p className="MsoNormal" style={{ textAlign: 'center' }}>
            <b><span style={{ fontSize: '13.0pt', color: 'white' }}>CONTENT SUBMISSION CONSENT &amp; RELEASE</span></b>
          </p>

          {/* {[...Array(4)].map(() => (
            <p className="MsoNormal">
              <b><span style={{ fontSize: '10.0pt', color: 'white' }}>&nbsp;</span></b>
            </p>
          ))} */}

          <p className="MsoNormal">
            <span style={{ fontSize: '10.0pt', color: 'white' }}>
              As part of my participation in the augmented reality website known as the A/R Experience (“Website”) and uploading a photo and any other content/filters or other information to the A/R experience website (“User Content”)
            </span>
          </p>

          <p className="MsoNormal" style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10.0pt', color: 'white' }}>
              For good and valuable consideration, in exchange for participating in and submitting User Content, I agree as follows:
            </span>
          </p>

          {/* ... Rest of the paragraphs ... */}
          {/* I will convert three of the paragraphs fully, and summarize the rest for brevity.  */}
          <p className="MsoNormal" style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10.0pt', color: 'white' }}>
            1.     I hereby grant to The Prudential Insurance Company of America (“Prudential”) and Playbill Inc. (“Playbill”) and , either of its agents and others working on either Prudential’s and/ or Playbill’s  behalf (collectively, the “Licensed Parties”), the right and permission to use, distribute, display, publish, exhibit, digitize, broadcast, reproduce, and otherwise exploit my name, image, recorded performance, voice, likeness, statements and actions, and any materials based or derived from these items (the “Work”), and to give others the right to make such use, in any manner that the Licensed Parties choose and in any media now known or hereinafter invented, throughout the world in perpetuity, for the purpose of advertising, promoting or publicizing Prudential’s or Playbill’s products and services and for any other lawful purpose.
            </span>
          </p>

          <p className="MsoNormal" style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10.0pt', color: 'white' }}>
            2.     I acknowledge that neither Prudential, Playbill nor any Licensed Party is required to compensate or credit me or anyone else in connection with this agreement or any such use.  Notwithstanding the foregoing, in the event the Work is used in such a manner as to be subject to the jurisdiction of any SAG-AFTRA collective bargaining agreement, I agree to execute a standard scale contract and accept minimum scale compensation for such use.
            </span>
          </p>

          <p className="MsoNormal" style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10.0pt', color: 'white' }}>
            3.     I agree that any and all materials produced and/or resulting from this agreement shall be owned exclusively by Prudential and Playbill and I have no rights, title, or interest in or to such materials. If for any reason any of the foregoing does not vest in Prudential and Playbill, I hereby assign to Prudential and Playbill all right, title and interest in and to the same.  If I should receive any print, negative, or other copy thereof, I shall not use nor authorize its use by anyone else.  I hereby waive my right to inspect or approve the Work, the User Content once I submit it  to the Website or any materials produced hereunder.  Nothing in this agreement requires the Licensed Parties or its licensees to make any use of the Work, the Program or any materials produced hereunder, or the rights granted herein.
            </span>
          </p>
          <p className="MsoNormal" style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10.0pt', color: 'white' }}>
            4.  I represent and warrant that (i) I have not given any third party the exclusive right to use my name, image, voice, likeness, or performance, and I am free to sign this agreement without conflict with any existing commitment on my part; and (ii) I am solely responsible for any government employment tax, insurance and immigration obligations that may results or arise from the use of my appearance or the Work hereunder.
            </span>
          </p>
          <p className="MsoNormal" style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10.0pt', color: 'white' }}>
            5.     I release the Licensed Parties, any company or person employed by or related to the Licensed Parties, and any person or company that obtains any rights from the Licensed Parties described above, from all liabilities and claims, whether known or unknown, asserted or non-asserted, that relate in any way to the rights I have given to the Licensed Parties above or my participation in the User Content including, but not limited to, those arising from or related to editorial acts, copyright, defamation, misrepresentation, faulty reproductions, violation of right of publicity or invasion of privacy.
            </span>
          </p>
          <p className="MsoNormal" style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10.0pt', color: 'white' }}>
            6.     I shall not make or authorize any public statement concerning this agreement, the User Content or the Licensed Parties without the prior consent written of the Licensed Parties in each instance.
            </span>
          </p>
          <p className="MsoNormal" style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10.0pt', color: 'white' }}>
            7.     I will regard and preserve in strict confidence all information related to the business, accounting, personnel, products and services of Licensed Parties, its affiliates and third parties with whom they do business (“Confidential Information”) that is not otherwise publicly available, which may be obtained by me from any source as a result of this agreement.  I will not, without first obtaining the Licensed Parties’ prior written consent, disclose to any person or use for my own benefit or for the benefit of any third party any Confidential Information.
            </span>
          </p>
          <p className="MsoNormal" style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10.0pt', color: 'white' }}>
            8.     This agreement contains the entire understanding between the parties regarding the subject matter hereof, supersedes all prior understandings and may not be modified except by a writing signed by both parties.  This agreement shall be construed in accordance with the laws of the State of New Jersey, without regard to any conflict of laws.
            </span>
          </p>

          <p className="MsoNormal" style={{ textAlign: 'justify' }}>
            <b><span style={{ fontSize: '10.0pt', color: 'white' }}>
            I fully understand the contents of this agreement and that the Licensed Parties are relying on the rights that I have granted to it, and I voluntarily agree to the agreement’s terms.  I am eighteen (18) years of age or older any by my submission of the User Content and clicking ‘Agree and Star On The Big Stage” I am bound by the terms of the agreement.
            </span></b>
          </p>

          {/* <p className="MsoNormal">
            <span style={{ fontSize: '10.0pt', color: 'white' }}>&nbsp;</span>
          </p>

          <p className="MsoNormal" style={{ textAlign: 'center' }}>
            <u><span style={{ fontSize: '10.0pt', color: 'white' }}><span style={{ textDecoration: 'none' }}>&nbsp;</span></span></u>
          </p> */}

        </div>
        <div className="back-fix-btn w-100 p-3 d-flex align-items-center justify-content-center">
          <Button
            variant="primary"
            className="fs-md text-uppercase fw-bold rounded-0 py-1 px-3 text-dark text-decoration-none"
            onClick={handleGoBack}
          >
            <Image src={leftArrow} alt="right arrow" color="#000000" className="me-2 text-black" />
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};
