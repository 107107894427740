import "./userInfo.scss";
import Joi from "joi";
import { useState } from "react";
import toast from "react-hot-toast";
import client from "../../services/axios";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import Header from "./../../assets/images/header.png";
import { MAX_CHAR_LENGTH } from "../../AppConstants/AppConstants";
import { CONFIRM_SCREEN, USER_INFO_API_PATH } from "../../AppConstants/AppConstants";
import { Button, Row, Form, DropdownButton, Dropdown, Modal } from "react-bootstrap";

export const UserInfoContainer = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nuCaps, setNuCaps] = useState(1);
  const [opt_1, setOpt_1] = useState(false);
  const [opt_2, setOpt_2] = useState(false);
  const [initials, setInitials] = useState(Array.from(Array(nuCaps)).fill(""));
  const [pnError, setPnError] = useState("");

  const setInitialValue = (index, value) => {
    let new_initials = [...initials];
    new_initials[index] = value;
    setInitials([...new_initials]);
  };

  const handleSelect = (eventKey) => {
    setInitials(Array.from(Array(parseInt(eventKey))).fill(""));
    setNuCaps(parseInt(eventKey));
  };
  const [messages, showMessages] = useState(false);

  const validateForm = () => {
    const schema = Joi.object().keys({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
      phoneNumber: Joi.string()
        .regex(/^(\+?\d{1,2}\s?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/)
        .message("Invalid phone number format. It should start with a plus sign and contain 7-15 digits."),
      nuCaps: Joi.number().required(),
      name: Joi.string().required(),
      initials: Joi.array().min(Joi.ref("nuCaps")).items(Joi.string().min(2).required()).required(),
      opt_1: Joi.boolean().required(),
      opt_2: Joi.boolean().required(),
    });
    const { error } = schema.validate({ email, name, phoneNumber, initials, nuCaps, opt_1, opt_2 });
    console.log({ initials, error });
    return error == undefined;
  };
  function isValidPhoneNumber(phoneNumber) {
    const phonePattern = /^(\+?\d{1,2}\s?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phonePattern.test(phoneNumber);
  }

  const handlePnChange = (e) => {
    setPhoneNumber(e);
    console.log({ e, status: isValidPhoneNumber(e) });
    if (!isValidPhoneNumber(e)) {
      setPnError("Not Valid Number");
    } else {
      setPnError("");
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (!form.checkValidity()) {
      event.stopPropagation();
    }
    showMessages(true);
    if (phoneNumber == "") setPnError("Invalid Number");
    if (!validateForm()) {
      return;
    }

    const toastId = toast.loading("Creating User Details");
    try {
      const payload = { name, email, phoneNumber, optIn1: opt_1, optIn2: opt_2, requestCapCount: nuCaps };
      Object.values(initials).map((value, i) => {
        Object.assign(payload, { [`initial${i + 1}`]: value });
      });
      console.log({ payload, initials });
      const response = await client.post(USER_INFO_API_PATH, payload);
      if (200 == response.status) {
        toast.success("User Details Saved Successfully !", { id: toastId });
        setTimeout(() => {
          toast.remove();
          navigate(CONFIRM_SCREEN);
        }, 1000);
      } else
        toast.error(response?.response?.data?.errorMessage ? "Invalid Number" : "Something went wrong!", {
          id: toastId,
        });
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong!", { id: toastId });
    }
  };
  const numberOptions = [1, 2, 3, 4, 5];
  const [showTerms, setShowTerms] = useState(false);
  return (
    <div style={{ maxWidth: "420px", margin: "auto", backgroundColor: "#5C5C5C" }}>
      <img style={{ width: "100%" }} src={Header} alt="" />
      <div className="px-4 text-sm">
        <p>To order your embroidered hat please complete the details below.</p>
        <p>
          Once entered you will receive a text message from our embroidery team. You will need to show the embroidery
          team the text message when you hand them your hat.
        </p>
        <p>We will send you a second text message when your hat is ready to collect.</p>
      </div>

      <Modal show={showTerms}>
        <div className="px-3 py-2 " style={{ backgroundColor: "#17150A" }}>
          <div className="d-flex flex-row justify-content-end">
            <i onClick={() => setShowTerms(!showTerms)} style={{ fontSize: "30px" }} className="bi bi-x"></i>
          </div>
          <h5>* Terms</h5>
          <section className="d-flex flex-row">
            <p className="px-2">*</p>
            <p style={{ fontSize: "0.8rem" }}>
              Consent to be contacted: I agree that The Prudential Insurance Company of America, Pruco Securities, LLC
              and their affiliates (collectively "Prudential") can contact me for marketing purposes by email at the
              email address I have provided. Consent is not required to make a purchase and I can opt out anytime.
            </p>
          </section>
          <section className="d-flex flex-row">
            <p className="px-2">*</p>
            <p style={{ fontSize: "0.8rem" }}>
              Consent to share and infon practices: By accessing Prudential's Privacy Center, I also understand how
              Prudential collects, uses, shares, and protects my personal information, as well as what my rights and
              choices are with respect to the personal information Prudential holds on me.
            </p>
          </section>
        </div>
      </Modal>
      <div style={{ backgroundColor: "#404040" }} className="m-4 p-2 rounded">
        <Form
          noValidate
          validated={messages}
          onSubmit={handleSubmit}
          className="d-flex flex-column align-items-center justify-content-center px-2 w-100"
        >
          <div className=" d-flex flex-column gap-3 w-100 ">
            <div>
              <p style={{ fontSize: "1rem", fontWeight: "bold" }} className="mt-4 m-1">
                How many hats do you have to embroider?
              </p>
              <Row>
                <Form.Group>
                  <DropdownButton title={`${nuCaps}`} onSelect={handleSelect}>
                    {numberOptions.map((number) => (
                      <Dropdown.Item key={number} eventKey={number}>
                        {number}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
              </Row>

              <p className="p-0 m-2 text-whitesmoke" style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                Enter initials to embroider on your hats.
              </p>
              <p className="p-0 m-0 text-secondary" style={{ fontSize: "0.9rem", textAlign: "right" }}>
                * Max 2 Characters
              </p>
            </div>

            {initials.map((a, i) => (
              <Row key={i}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    maxLength={2}
                    required
                    minLength={2}
                    className="form-control placeholder-white bg-white rounded-5 fw-semibold  px-3 py-3 text-black"
                    placeholder="Initial"
                    isValid={/^.{2}$/.test(initials[i])}
                    value={initials[i]}
                    onChange={(evt) => {
                      if (/^[A-Za-z0-9 ]*$/.test(evt.target.value)) {
                        setInitialValue(i, `${evt.target.value}`.toLocaleUpperCase());
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Initials can not be empty / Single Character
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            ))}
            <Row>
              <Form.Group>
                <Form.Control
                  id="formName"
                  type="text"
                  name="name"
                  required
                  maxLength={MAX_CHAR_LENGTH}
                  className="form-control placeholder-white bg-white rounded-5 fw-semibold  px-3 py-3 text-black"
                  placeholder="Full Name"
                  value={name}
                  onChange={(evt) => setName(evt.target.value)}
                />
                <Form.Control.Feedback type="invalid">Name is Required</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group>
                <div
                  style={{ borderColor: messages && pnError !== "" ? "red" : "unset" }}
                  className="form-control placeholder-white bg-white rounded-5 fw-semibold  px-3 py-3 text-black"
                >
                  <PhoneInput
                    limitMaxLength
                    defaultCountry="US"
                    addInternationalOption={false}
                    countries={JSON.parse(process.env.REACT_APP_COUNTRY_CODES) ?? ["US"]}
                    placeholder="Mobile Number"
                    value={phoneNumber}
                    onChange={(e) => handlePnChange(e)}
                  />
                </div>
                {messages && pnError !== "" ? (
                  <div style={{ color: "red", fontSize: "0.875rem" }}>{pnError}</div>
                ) : null}
              </Form.Group>
            </Row>

            <Row className="justify-content-center">
              <Form.Group>
                <Form.Control
                  id="email"
                  type="email"
                  name="email"
                  required
                  className="form-control placeholder-white bg-white rounded-5 fw-semibold  px-3 py-3 text-black"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
              </Form.Group>
            </Row>

            {/* <Row>
              <Form.Group>
                <Form.Check type="checkbox" id="opt-1">
                  <Form.Check.Input type="checkbox" value={opt_1} onChange={(e) => setOpt_1(e.target.checked)} />
                  <Form.Label className="px-2">
                    Opt-In 1<a> Privacy Polices</a>
                  </Form.Label>
                </Form.Check>
              </Form.Group>
            </Row> */}
            {/* <Row>
              <Form.Group>
                <Form.Check type="checkbox" id="opt-1">
                  <Form.Check.Input type="checkbox" value={opt_2} onChange={(e) => setOpt_2(e.target.checked)} />
                  <Form.Label className="px-2">
                    Opt-In 2
                    <a className="px-2" style={{ color: "purple" }} onClick={() => setShowTerms(!showTerms)}>
                      Terms
                    </a>
                  </Form.Label>
                </Form.Check>
              </Form.Group>
            </Row> */}
          </div>
          <Button
            style={{ width: "90%", height: "2.5rem" }}
            type="submit"
            className="my-4 "
            variant="warning"
            // disabled={!validateForm()}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
        </Form>
      </div>
      <div className="mx-5" style={{ fontSize: "0.8rem" }}>
        <Row>
          <Form.Group>
            <Form.Check type="checkbox" id="opt-1">
              <div className="d-flex flex-row">
                <Form.Check.Input
                  size="lg"
                  type="checkbox"
                  value={opt_2}
                  onChange={(e) => {
                    if (e.target.checked) setShowTerms(true);
                    setOpt_2(e.target.checked);
                  }}
                />
                <Form.Label className="px-2">
                  YES, I want to subscribe to receive Prudential emails.
                  <a className="px-2" style={{ color: "white" }} onClick={() => setShowTerms(!showTerms)}>
                    *See Terms
                  </a>
                </Form.Label>
              </div>
            </Form.Check>
          </Form.Group>
        </Row>
      </div>
      <br />
      <div className="px-4 mb-4">
        <p>
          Unless you consent to be contacted for marketing purposes we are only collecting your phone number to inform
          you that your hat is ready for pickup. Your phone number will not be used for marketing purposes.
        </p>
        <p>
          Standard Carrier rates for calls, text, SMS, and/or MMS may apply. Please check with your carrier about your
          rate plan for questions regarding your cellular plan charges.
        </p>
      </div>
    </div>
  );
};
